/* eslint-disable no-undef */
import React, { Component, useState } from 'react'
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from 'react-router-dom'
import './scss/style.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoadingBar from 'react-top-loading-bar'
// import Protected from './protected'
const loading = (
  <div style={{ backgroundColor: '#fffaf1  ' }} className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {

  render() {
    
    return (
      <HashRouter >
        <React.Suspense fallback={loading}>
          <Switch>

            <Route exact path="/" name="LoginPage" render={(props) => <Login {...props} />} />
            {/* <Route exact path="/dashboard" name="Dashboard Page" render={(props) => <Dashboard {...props} />} /> */}
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />

            {/* {localStorage.getItem('studentdata') ? (
              <> */}
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
              {/* </>
            ) : (
              <> */}
              {/* <Route path="/">
                <Redirect to="/login" />
               </Route> */}
              {/* </>
            )} */}
          </Switch>
          <ToastContainer autoClose={5000}  />
          {/* <PDFReader/> */}
        </React.Suspense>
      </HashRouter>
    )
  }
}
export default App
