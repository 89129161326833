import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css';
import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
// import axios from 'axios'
// axios.defaults.headers.common['Authorization'] = 'Bearer' + localStorage.getItem('token')
// window.name = 'http://127.0.0.1:8009/'
// window.topicText='http://127.0.0.1:8009'


// window.name = 'http://206.189.179.85/'

window.topicText='https://www.login.nrutyarpan.com'
window.name = 'https://www.login.nrutyarpan.com/'
// window.PointerEvent = false
var a = document.getElementById('disable')
React.icons = icons

// window.onbeforeunload = () => {
//   localStorage.removeItem('studentdata');
// }
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()


